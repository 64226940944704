.send_tokens_container {
    display: flex;
    flex-direction: column;
    gap: 10px;

    h3 {
        text-align: left;
        font-family: 'montserrat', sans-serif;
        font-weight: 600;
        font-size: 18px;
    }

    .inputs_container {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        gap: 10px;
        margin-top: 10px;

        input, select {
            width: 100%;
            outline: none;
            border: 1px solid #818a91;
            color: #7A7A7A;
            padding: 10px;
            font-size: 15px;
            border-radius: 3px;
        }
    }

    .buttons_container {
        display: flex;
        justify-content: space-between;
        gap: 10px;

        button {
            width: 100%;
        }
    }

    @media screen and (max-width: 750px) {
        .buttons_container {
            flex-direction: column;
            gap: 5px;
        }
    }
}