.transactions {
    &_container {
        margin-top: 30px;
        width: 100%;
    
        table {
            width: 100%;
    
            tr {
                height: 50px;
    
                th,
                td {
                    text-align: left;
                }
    
                th.button_column {
                    width: 250px;
                }
    
                .btn {
                    width: 100%;
                }
            }
        }
    }

    &_loading {
        position: fixed;
        bottom: 16px;
        right: 16px;
        display: flex;
        justify-content: center;
        align-items: center;
        background: #c0c0c0;
        border: 1px solid #a5a5a5;
        color: #fff;
        border-radius: 4px;
        height: 32px;
        width:  160px;

        span {
            z-index: 2; 
        }

        &::after {
            content: "";
            display: block;
            top: 0;
            left: 0;
            position: absolute;
            background: var(--primary);
            width: var(--progress);
            height: 100%;
            z-index: 0;
            transition: 1s ease all;
        }
    }
}