*,
*::after,
*::before {
    box-sizing: border-box;
    margin: 0;
    padding: 0;
}

:root {
    --primary: #61CE70;
}

body {
    margin: 0;
    min-width: 100vw;
    min-height: 100vh;
    font-family: "roboto", sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

a {
    color: black;
    text-decoration: none;

    &:hover {
        text-decoration: underline;
    }
}